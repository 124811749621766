import { initializeApp } from "firebase/app";
import { initializeAuth, browserLocalPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = initializeAuth(firebaseApp, {
  persistence: browserLocalPersistence,
});
export const db = getFirestore(firebaseApp);

export const functions = getFunctions(firebaseApp);

// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// export const appCheck = initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider("6LdwYOApAAAAALc8zpLvfmpgMkX48ufESofYfdjm"),
//   isTokenAutoRefreshEnabled: true,
// });
