import React, { useEffect, useState } from "react";
import { CButton, CSpinner } from "@coreui/react";
import "src/scss/_custom.scss";
import { COLORS, IMAGENES } from "src/constants/theme";
import WhatsAppButton from "src/components/WhatsAppButton";

const LoadingHome = ({ isLoading = true, contactPhoneNumber }) => {
  const [imageUp, setImageUp] = useState(true);

  useEffect(() => {
    const toggleImagePosition = () => {
      setImageUp((prevImageUp) => !prevImageUp);
    };

    const interval = setInterval(toggleImagePosition, 500);

    toggleImagePosition();

    return () => {
      clearInterval(interval);
    };
  }, []);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  };

  const spinnerStyle = {
    marginTop: "20px",
  };

  const sombraStyle = {
    width: imageUp ? "60px" : "100px",
    background: imageUp ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0.3)",
  };

  const imagenStyle = {
    transform: imageUp ? "translateY(-20px)" : "translateY(0)",
  };

  const abrirEnlaceExterno = () => {
    const url = "https://www.sorteosherraduraroja.com";
    window.open(url, "_blank");
  };

  return (
    <div style={containerStyle}>
      <div className="contenedor-imagen">
        <div className="sombra" style={sombraStyle}></div>
        <img
          src={IMAGENES.logo}
          alt="Logo"
          className="imagen"
          style={imagenStyle}
        />
      </div>
      {isLoading ? (
        <CSpinner color="primary" style={spinnerStyle} />
      ) : (
        <>
          <h2
            className="titulos mt-2"
            style={{
              color: COLORS.brown,
            }}
          >
            <a
              href="http://www.sorteosherraduraroja.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: COLORS.secondary,
                textDecoration: "none",
              }}
              className="link-with-border"
            >
              www.sorteosherraduraroja.com
            </a>
          </h2>
          <CButton
            className="button-magic mt-2"
            onClick={() => {
              alert(
                "Para continuar, abre esta página en tu navegador predeterminado."
              );
              abrirEnlaceExterno();
            }}
          >
            ¡Únete a la estampida de la Suerte!
          </CButton>
          <WhatsAppButton phone={contactPhoneNumber} />
        </>
      )}
    </div>
  );
};

export default LoadingHome;
