import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
  orderBy,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import { auth, db } from "./Firebase";
import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
  updateProfile,
  sendEmailVerification,
  verifyBeforeUpdateEmail,
  signInAnonymously,
} from "firebase/auth";
import { mostrarMensajeError, mostrarMensajeExito } from "./Mensajes";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { convertirFicheroBlob, normalizarTexto } from "./Utils";
import { isEmpty, map, size } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { endOfDay, startOfDay } from "date-fns";
import { PERFIL } from "src/constants/theme";

export const validarSesion = (setValidarSesion) => {
  try {
    auth.onAuthStateChanged((user) => {
      setValidarSesion(user);
    });
  } catch (error) {
    mostrarMensajeError(
      "Error al validar la sesión",
      "Hubo un problema al intentar validar la sesión. Por favor, inténtalo de nuevo más tarde."
    );
  }
};

export const iniciarSesion = async (email, password) => {
  const auth = getAuth();
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    return user;
  } catch (error) {
    manejarErrorInicioSesion(
      error,
      true,
      "Se produjo un error al intentar iniciar sesión. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
};

export const signInAnonymouslyWithFirebase = async () => {
  const auth = getAuth();
  try {
    const result = await signInAnonymously(auth);
    return result.user;
  } catch (error) {
    await registrarError(error);
    throw error;
  }
};

const manejarErrorInicioSesion = (error, inicioSesion, mensajeDefault) => {
  let titulo = inicioSesion
    ? "Error al iniciar sesión"
    : "Error al registrarse";
  let mensaje;

  switch (error.code) {
    case "auth/invalid-login-credentials":
      mensaje =
        "Las credenciales proporcionadas son incorrectas. Por favor, verifica tu correo y contraseña.";
      break;
    case "auth/claims-too-large":
      mensaje =
        "La carga útil de la reclamación supera el tamaño máximo permitido.";
      break;
    case "auth/email-already-exists":
      mensaje =
        "Otro usuario ya está utilizando el correo electrónico proporcionado.";
      break;
    case "auth/id-token-expired":
      mensaje = "El token de ID de Firebase proporcionado está vencido.";
      break;
    case "auth/id-token-revoked":
      mensaje = "Se revocó el token de ID de Firebase.";
      break;
    case "auth/insufficient-permission":
      mensaje =
        "La credencial que se usó para inicializar el SDK de Admin no tiene permisos suficientes para acceder al recurso de Authentication solicitado.";
      break;
    case "auth/internal-error":
      mensaje =
        "El servidor de Authentication encontró un error inesperado cuando se intentaba procesar la solicitud.";
      break;
    case "auth/invalid-argument":
      mensaje =
        "Se proporcionó un argumento no válido para un método de autenticación.";
      break;
    case "auth/invalid-claims":
      mensaje =
        "Los atributos personalizados del reclamo que se entregaron no son válidos.";
      break;
    case "auth/invalid-continue-uri":
      mensaje = "La URL de continuación proporcionada no es válida.";
      break;
    case "auth/invalid-creation-time":
      mensaje =
        "La hora de creación debe ser una string de fecha en formato UTC válida.";
      break;
    case "auth/invalid-credential":
      mensaje =
        "La credencial proporcionada no es válida para la acción deseada.";
      break;
    case "auth/invalid-disabled-field":
      mensaje =
        "El valor proporcionado para la propiedad 'disabled' no es válido. Debe ser un booleano.";
      break;
    case "auth/invalid-display-name":
      mensaje =
        "El valor proporcionado para la propiedad 'displayName' no es válido. Debe ser una string no vacía.";
      break;
    case "auth/invalid-dynamic-link-domain":
      mensaje =
        "El dominio del vínculo dinámico proporcionado no está configurado o autorizado para el proyecto actual.";
      break;
    case "auth/invalid-email":
      mensaje = "El formato del correo electrónico no es válido.";
      break;
    case "auth/invalid-email-verified":
      mensaje =
        "El valor proporcionado para la propiedad 'emailVerified' no es válido. Debe ser un booleano.";
      break;
    case "auth/invalid-hash-algorithm":
      mensaje = "El algoritmo de hash proporcionado no es válido.";
      break;
    case "auth/invalid-hash-block-size":
      mensaje = "El tamaño del bloque de hash proporcionado no es válido.";
      break;
    case "auth/invalid-hash-derived-key-length":
      mensaje =
        "La longitud de la clave derivada de hash proporcionada no es válida.";
      break;
    case "auth/invalid-hash-key":
      mensaje = "La clave de hash proporcionada no es válida.";
      break;
    case "auth/invalid-hash-memory-cost":
      mensaje = "El costo de memoria del hash proporcionado no es válido.";
      break;
    case "auth/invalid-hash-parallelization":
      mensaje = "La paralelización del hash proporcionada no es válida.";
      break;
    case "auth/invalid-hash-rounds":
      mensaje = "Las rondas del hash proporcionadas no son válidas.";
      break;
    case "auth/invalid-hash-salt-separator":
      mensaje = "El separador de sal del hash proporcionado no es válido.";
      break;
    case "auth/invalid-id-token":
      mensaje = "El token de ID proporcionado no es válido.";
      break;
    case "auth/invalid-last-sign-in-time":
      mensaje =
        "La hora del último inicio de sesión debe ser una string de fecha en formato UTC válida.";
      break;
    case "auth/invalid-page-token":
      mensaje = "El token de página proporcionado no es válido.";
      break;
    case "auth/invalid-password":
      mensaje = "La contraseña proporcionada no es válida.";
      break;
    case "auth/invalid-password-hash":
      mensaje = "El hash de la contraseña proporcionada no es válido.";
      break;
    case "auth/invalid-password-salt":
      mensaje = "La sal de la contraseña proporcionada no es válida.";
      break;
    case "auth/invalid-phone-number":
      mensaje = "El número de teléfono proporcionado no es válido.";
      break;
    case "auth/invalid-photo-url":
      mensaje = "La URL de la foto proporcionada no es válida.";
      break;
    case "auth/invalid-provider-data":
      mensaje = "Los datos del proveedor proporcionados no son válidos.";
      break;
    case "auth/invalid-provider-id":
      mensaje = "El ID del proveedor proporcionado no es válido.";
      break;
    case "auth/invalid-oauth-responsetype":
      mensaje = "El tipo de respuesta de OAuth proporcionado no es válido.";
      break;
    case "auth/invalid-session-cookie-duration":
      mensaje =
        "La duración de la cookie de sesión proporcionada no es válida.";
      break;
    case "auth/invalid-uid":
      mensaje = "El UID proporcionado no es válido.";
      break;
    case "auth/invalid-user-import":
      mensaje = "La importación de usuarios proporcionada no es válida.";
      break;
    case "auth/maximum-user-count-exceeded":
      mensaje = "Se ha excedido el número máximo de usuarios permitidos.";
      break;
    case "auth/missing-android-pkg-name":
      mensaje = "Falta el nombre del paquete de Android.";
      break;
    case "auth/missing-continue-uri":
      mensaje = "Falta la URL de continuación.";
      break;
    case "auth/missing-hash-algorithm":
      mensaje = "Falta el algoritmo de hash.";
      break;
    case "auth/missing-ios-bundle-id":
      mensaje = "Falta el ID del paquete iOS.";
      break;
    case "auth/missing-uid":
      mensaje = "Falta el UID.";
      break;
    case "auth/missing-oauth-client-secret":
      mensaje = "Falta el secreto del cliente OAuth.";
      break;
    case "auth/operation-not-allowed":
      mensaje = "La operación no está permitida.";
      break;
    case "auth/phone-number-already-exists":
      mensaje = "El número de teléfono proporcionado ya está en uso.";
      break;
    case "auth/project-not-found":
      mensaje = "No se encontró el proyecto de Firebase.";
      break;
    case "auth/reserved-claims":
      mensaje = "Una o más reclamaciones personalizadas están reservadas.";
      break;
    case "auth/session-cookie-expired":
      mensaje = "La cookie de sesión ha expirado.";
      break;
    case "auth/session-cookie-revoked":
      mensaje = "La cookie de sesión ha sido revocada.";
      break;
    case "auth/too-many-requests":
      mensaje = "Se han realizado demasiadas solicitudes.";
      break;
    case "auth/uid-already-exists":
      mensaje = "El UID proporcionado ya está en uso.";
      break;
    case "auth/unauthorized-continue-uri":
      mensaje = "La URL de continuación no está autorizada.";
      break;
    case "auth/user-not-found":
      mensaje =
        "No se encontró ningún usuario que coincida con el identificador proporcionado.";
      break;
    default:
      mensaje = mensajeDefault;
      break;
  }
  mostrarMensajeError(titulo, mensaje);
};

export const signup = async (email, password) => {
  try {
    const user = await createUserWithEmailAndPassword(auth, email, password);
    return user != null;
  } catch (error) {
    manejarErrorInicioSesion(
      error,
      false,
      "Se produjo un error al intentar registrarse. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
    return false;
  }
};

export const signOutUser = async (navigate) => {
  try {
    await auth.signOut();
    navigate("/");
    window.location.reload();
  } catch (error) {
    mostrarMensajeError(
      "Error al cerrar sesión",
      "Hubo un problema al cerrar sesión. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
};

export const ObtenerUsuario = () => {
  return auth.currentUser;
};

export const actualizarPerfil = async (data) => {
  try {
    await updateProfile(ObtenerUsuario(), data);
    return true;
  } catch (error) {
    mostrarMensajeError(
      "Error al actualizar el perfil",
      "Hubo un problema al actualizar el perfil. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
    return false;
  }
};

export const addRegistro = async (coleccion, data) => {
  const reference = collection(db, coleccion);

  try {
    const newDoc = await addDoc(reference, data);
    return { error: "", statusresponse: true, id: newDoc.id };
  } catch (error) {
    mostrarMensajeError(
      "Error al agregar el registro",
      "Hubo un problema al agregar el registro. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
    return { error, statusresponse: false, id: "" };
  }
};

export const addRegistroEspecifico = async (coleccion, path, data) => {
  const reference = doc(db, coleccion, path);

  try {
    await setDoc(reference, data, { merge: true });
    return { error: "", statusresponse: true, id: path };
  } catch (error) {
    mostrarMensajeError(
      "Error al agregar el registro",
      "Hubo un problema al agregar el registro. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
    return { error, statusresponse: false, id: "" };
  }
};

export const eliminarData = async (coleccion, documento) => {
  const reference = doc(db, coleccion, documento);

  try {
    await deleteDoc(reference);
    return { error: "", statusresponse: true };
  } catch (error) {
    mostrarMensajeError(
      "Error al eliminar los datos",
      "Hubo un problema al eliminar los datos. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
    return { error, statusresponse: false };
  }
};

export const obternerRegistroxID = async (coleccion, documento) => {
  let response = { statusresponse: false, data: null };

  try {
    const docRef = doc(db, coleccion, documento);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const visita = docSnapshot.data();
      visita.id = docSnapshot.id;
      response.data = visita;
      response.statusresponse = true;
    } else {
      mostrarMensajeError(
        "Error al obtener la información",
        "Hubo un problema al obtener la información. Por favor, inténtalo de nuevo más tarde."
      );
    }
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener la información",
      "Hubo un problema al obtener la información. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
};

export const obtenerRegistroxIDTiempoReal = async (
  coleccion,
  documento,
  callback
) => {
  try {
    const docRef = doc(db, coleccion, documento);

    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const visita = docSnapshot.data();
        visita.id = docSnapshot.id;
        callback({ statusresponse: true, data: visita });
      } else {
        callback({ statusresponse: false, data: null });
      }
    });
    return unsubscribe;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener la información",
      "Hubo un problema al obtener la información. Por favor, inténtalo de nuevo más tarde."
    );
    return { statusresponse: false, data: null };
  }
};

export const subirImagen = async (imagen, ruta) => {
  if (!imagen || !ruta) {
    return "";
  }
  try {
    const storage = getStorage();
    const imageRef = ref(storage, `${ruta}/${uuidv4()}`);
    const blob = await convertirFicheroBlob(imagen);
    const result = await uploadBytes(imageRef, blob);
    const urlRef = ref(storage, `${ruta}/${result.metadata.name}`);
    return await getDownloadURL(urlRef);
  } catch (error) {
    mostrarMensajeError(
      "Error al subir imagen",
      "Hubo un problema al subir la imagen. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
    return "";
  }
};

export const subirImagenesBatch = async (imagenes, ruta) => {
  try {
    return await Promise.all(
      map(imagenes, async (imagen) => {
        return await subirImagen(imagen, ruta);
      })
    );
  } catch (error) {
    mostrarMensajeError(
      "Error al subir imágenes",
      "Hubo un problema al subir las imágenes. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
};

export async function getSorteos(startDate, endDate, filterState, ubicacion) {
  const statusQuery = where("status", "==", "ACTIVO");
  const prioridadQuery = orderBy("prioridad", "asc");
  const prioridadFecha = orderBy("fechaInicio", "desc");

  let sorteosQuery = query(
    collection(db, "SORTEOS"),
    statusQuery,
    prioridadQuery,
    prioridadFecha
  );

  if (process.env.REACT_APP_THEME !== "SORTEOS_HERRADURA_ROJA") {
    const proveedorRef = doc(db, "USUARIOS", PERFIL.ID);
    sorteosQuery = query(sorteosQuery, where("proveedor", "==", proveedorRef));
  }

  if (startDate && endDate) {
    const adjustedStartDate = startOfDay(startDate);
    const adjustedEndDate = endOfDay(endDate);
    sorteosQuery = query(
      sorteosQuery,
      where("fechaInicio", ">=", adjustedStartDate),
      where("fechaInicio", "<=", adjustedEndDate)
    );
  }

  if (!isEmpty(ubicacion)) {
    sorteosQuery = query(
      sorteosQuery,
      where("ubicacion.estado", "==", ubicacion)
    );
  }

  if (filterState && !isEmpty(filterState.value)) {
    switch (filterState.filter) {
      case "nombre_sorteo":
        const nombreSorteoNormalizado = normalizarTexto(filterState.value);
        // sorteosQuery = query(
        //   sorteosQuery,
        //   where("nombre", ">=", filterState.value),
        //   where("nombre", "<=", filterState.value + "\uf8ff")
        // );
        sorteosQuery = query(
          sorteosQuery,
          where(
            "nombre_palabras_clave",
            "array-contains",
            nombreSorteoNormalizado
          )
        );
        break;
      case "metodo_seleccion_ganadores":
        const metodoDoc = doc(
          db,
          "METODOS_SELECCION_GANADORES",
          filterState.value
        );
        sorteosQuery = query(
          sorteosQuery,
          where("metodoSeleccionGanadores", "==", metodoDoc)
        );
        break;
      case "costo_participacion":
        sorteosQuery = query(
          sorteosQuery,
          where("costoParticipacion", ">=", filterState.value.min || 0),
          where("costoParticipacion", "<=", filterState.value.max || 0)
        );
        break;
      default:
        break;
    }
  }

  try {
    const sorteosSnapshot = await getDocs(sorteosQuery);
    const sorteosList = sorteosSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return sorteosList;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener sorteos",
      "Hubo un problema al obtener los sorteos. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
    return [];
  }
}

export async function getBoletos(id, callback) {
  try {
    const sorteoDoc = doc(db, "SORTEOS", id);

    const q = query(
      collection(db, "BOLETOS"),
      where("sorteo", "==", sorteoDoc),
      where("status", "in", ["APARTADO", "COMPRADO"])
    );

    onSnapshot(q, (querySnapshot) => {
      const boletos = [];
      querySnapshot.forEach((doc) => {
        const boleto = doc.data();
        boleto.id = doc.id;
        boletos.push(boleto);
      });
      callback({ statusresponse: size(boletos) > 0, data: boletos });
    });
  } catch (error) {
    callback({ statusresponse: false, error });
  }
}

export async function getBoletosLucky(id) {
  try {
    const sorteoDoc = doc(db, "SORTEOS", id);
    const q = query(
      collection(db, "BOLETOS"),
      where("sorteo", "==", sorteoDoc),
      where("status", "in", ["APARTADO", "COMPRADO"])
    );

    const querySnapshot = await getDocs(q);
    const boletos = [];
    querySnapshot.forEach((doc) => {
      const boleto = doc.data();
      boleto.id = doc.id;
      boletos.push(boleto);
    });

    return { statusresponse: boletos.length > 0, data: boletos };
  } catch (error) {
    await registrarError(error);
    return { statusresponse: false, error };
  }
}

export async function getBoletosComprados(id, callback) {
  try {
    const sorteoDoc = doc(db, "SORTEOS", id);

    const q = query(
      collection(db, "BOLETOS"),
      where("sorteo", "==", sorteoDoc),
      where("status", "==", "COMPRADO")
    );

    onSnapshot(q, (querySnapshot) => {
      const boletos = [];
      querySnapshot.forEach((doc) => {
        const boleto = doc.data();
        boleto.id = doc.id;
        boletos.push(boleto);
      });
      callback({ statusresponse: size(boletos) > 0, data: boletos });
    });
  } catch (error) {
    callback({ statusresponse: false, error });
  }
}

export async function getMisBoletosComprados(sorteo) {
  const response = { statusresponse: false, data: [] };
  try {
    const sorteoDoc = doc(db, "SORTEOS", sorteo);

    const q = query(
      collection(db, "BOLETOS"),
      where("sorteo", "==", sorteoDoc),
      where("status", "==", "COMPRADO")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const boleto = doc.data();
      boleto.id = doc.id;
      response.data.push(boleto);
    });

    response.statusresponse = size(response.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener boletos",
      "Hubo un problema al obtener los boletos. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
}

export async function getMisBoletos(usuario) {
  const response = { statusresponse: false, data: [] };
  try {
    const usuarioDoc = doc(db, "USUARIOS", usuario);

    const q = query(
      collection(db, "BOLETOS"),
      where("usuario", "==", usuarioDoc),
      orderBy("fechaApartado", "desc")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const boleto = doc.data();
      boleto.id = doc.id;
      response.data.push(boleto);
    });

    response.statusresponse = size(response.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener boletos",
      "Hubo un problema al obtener los boletos. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
}

export async function getMisSorteos(
  id,
  startDate,
  endDate,
  filterState,
  ubicacion
) {
  const response = { statusresponse: false, data: [] };
  const usuarioDoc = doc(db, "USUARIOS", id);
  const usuarioQuery = where("proveedor", "==", usuarioDoc);
  const prioridadFecha = orderBy("fechaInicio", "desc");

  let sorteosQuery = query(
    collection(db, "SORTEOS"),
    usuarioQuery,
    prioridadFecha
  );

  if (startDate && endDate) {
    const adjustedStartDate = startOfDay(startDate);
    const adjustedEndDate = endOfDay(endDate);
    sorteosQuery = query(
      sorteosQuery,
      where("fechaInicio", ">=", adjustedStartDate),
      where("fechaInicio", "<=", adjustedEndDate)
    );
  }

  if (!isEmpty(ubicacion)) {
    sorteosQuery = query(
      sorteosQuery,
      where("ubicacion.estado", "==", ubicacion)
    );
  }

  if (filterState && !isEmpty(filterState.value)) {
    switch (filterState.filter) {
      case "nombre_sorteo":
        const nombreSorteoNormalizado = normalizarTexto(filterState.value);
        // sorteosQuery = query(
        //   sorteosQuery,
        //   where("nombre", ">=", filterState.value),
        //   where("nombre", "<=", filterState.value + "\uf8ff")
        // );
        sorteosQuery = query(
          sorteosQuery,
          where(
            "nombre_palabras_clave",
            "array-contains",
            nombreSorteoNormalizado
          )
        );
        break;
      case "metodo_seleccion_ganadores":
        const metodoDoc = doc(
          db,
          "METODOS_SELECCION_GANADORES",
          filterState.value
        );
        sorteosQuery = query(
          sorteosQuery,
          where("metodoSeleccionGanadores", "==", metodoDoc)
        );
        break;
      case "costo_participacion":
        sorteosQuery = query(
          sorteosQuery,
          where("costoParticipacion", ">=", filterState.value.min || 0),
          where("costoParticipacion", "<=", filterState.value.max || 0)
        );
        break;
      default:
        break;
    }
  }

  try {
    const querySnapshot = await getDocs(sorteosQuery);
    querySnapshot.forEach((doc) => {
      const sorteo = doc.data();
      sorteo.id = doc.id;
      response.data.push(sorteo);
    });

    response.statusresponse = size(response.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener sorteos",
      "Hubo un problema al obtener los sorteos. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
}

export async function getMisSorteosDisponibilidad(
  id,
  disponible,
  startDate,
  endDate,
  filterState,
  ubicacion
) {
  const response = { statusresponse: false, data: [] };
  const usuarioDoc = doc(db, "USUARIOS", id);
  const usuarioQuery = where("proveedor", "==", usuarioDoc);
  const disponibilidadQuery = where("disponible", "==", disponible);
  const prioridadFecha = orderBy("fechaInicio", "desc");

  let sorteosQuery = query(
    collection(db, "SORTEOS"),
    usuarioQuery,
    disponibilidadQuery,
    prioridadFecha
  );

  if (!isEmpty(ubicacion)) {
    sorteosQuery = query(
      sorteosQuery,
      where("ubicacion.estado", "==", ubicacion)
    );
  }

  if (startDate && endDate) {
    const adjustedStartDate = startOfDay(startDate);
    const adjustedEndDate = endOfDay(endDate);
    sorteosQuery = query(
      sorteosQuery,
      where("fechaInicio", ">=", adjustedStartDate),
      where("fechaInicio", "<=", adjustedEndDate)
    );
  }

  if (filterState && !isEmpty(filterState.value)) {
    switch (filterState.filter) {
      case "nombre_sorteo":
        const nombreSorteoNormalizado = normalizarTexto(filterState.value);
        // sorteosQuery = query(
        //   sorteosQuery,
        //   where("nombre", ">=", filterState.value),
        //   where("nombre", "<=", filterState.value + "\uf8ff")
        // );
        sorteosQuery = query(
          sorteosQuery,
          where(
            "nombre_palabras_clave",
            "array-contains",
            nombreSorteoNormalizado
          )
        );
        break;
      case "metodo_seleccion_ganadores":
        const metodoDoc = doc(
          db,
          "METODOS_SELECCION_GANADORES",
          filterState.value
        );
        sorteosQuery = query(
          sorteosQuery,
          where("metodoSeleccionGanadores", "==", metodoDoc)
        );
        break;
      case "costo_participacion":
        sorteosQuery = query(
          sorteosQuery,
          where("costoParticipacion", ">=", filterState.value.min || 0),
          where("costoParticipacion", "<=", filterState.value.max || 0)
        );
        break;
      default:
        break;
    }
  }

  try {
    const querySnapshot = await getDocs(sorteosQuery);
    querySnapshot.forEach((doc) => {
      const sorteo = doc.data();
      sorteo.id = doc.id;
      response.data.push(sorteo);
    });

    response.statusresponse = size(response.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener sorteos",
      "Hubo un problema al obtener los sorteos. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
}

export async function getMisBoletosStatus(
  sorteoId,
  status,
  startDate,
  endDate,
  filterState,
  sorteo
) {
  const response = { statusresponse: false, data: [] };
  try {
    const sorteoDoc = doc(db, "SORTEOS", sorteoId);

    let q = query(
      collection(db, "BOLETOS"),
      where("sorteo", "==", sorteoDoc),
      where("status", "==", status),
      orderBy("fechaApartado", "desc")
    );

    if (startDate && endDate) {
      q = query(
        q,
        where("fechaApartado", ">=", startDate),
        where("fechaApartado", "<=", endDate)
      );
    }

    if (filterState && !isEmpty(filterState.value)) {
      switch (filterState.filter) {
        case "nombre_comprador":
          const nombreCompradorNormalizado = normalizarTexto(filterState.value);
          // q = query(
          //   q,
          //   where("comprador.nombre", ">=", filterState.value),
          //   where("comprador.nombre", "<=", filterState.value + "\uf8ff")
          // );
          q = query(
            q,
            where(
              "comprador.nombre_palabras_clave",
              "array-contains",
              nombreCompradorNormalizado
            )
          );
          break;
        case "numero_celular":
          q = query(
            q,
            where("comprador.phone", ">=", filterState.value),
            where("comprador.phone", "<=", filterState.value + "\uf8ff")
          );
          break;
        case "email":
          q = query(
            q,
            where("comprador.email", ">=", filterState.value),
            where("comprador.email", "<=", filterState.value + "\uf8ff")
          );
          break;
        case "boleto":
          let boleto = parseInt(filterState.value);
          if (
            !isNaN(boleto) &&
            boleto >= 1 &&
            boleto <= (sorteo?.boletosDisponibles || 0)
          ) {
            boleto = String(boleto).padStart(sorteo?.digitosBoleto || 6, "0");

            q = query(q, where("boletos", "array-contains", boleto));
          }
          break;
      }
    }

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const boleto = doc.data();
      boleto.id = doc.id;
      response.data.push(boleto);
    });

    response.statusresponse = size(response.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener boletos",
      "Hubo un problema al obtener los boletos. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
}

export async function getMisBoletosInvalidos(
  sorteoId,
  status,
  startDate,
  endDate
) {
  const response = { statusresponse: false, data: [] };
  try {
    const sorteoDoc = doc(db, "SORTEOS", sorteoId);

    let q = query(
      collection(db, "BOLETOS"),
      where("sorteo", "==", sorteoDoc),
      where("status", "==", status),
      where("boletosInvalidos", "!=", null)
    );

    if (startDate && endDate) {
      q = query(
        q,
        where("fechaApartado", ">=", startDate),
        where("fechaApartado", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const boleto = doc.data();
      boleto.id = doc.id;
      if (!boleto.hasOwnProperty("reembolso")) {
        response.data.push(boleto);
      }
    });

    response.statusresponse = size(response.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener boletos inválidos",
      "Hubo un problema al obtener los boletos inválidos. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
}

export const updateStatusOfAll = async (sorteoId) => {
  const response = { statusresponse: false, error: null };
  try {
    const sorteoDoc = doc(db, "SORTEOS", sorteoId);

    const q = query(
      collection(db, "BOLETOS"),
      where("sorteo", "==", sorteoDoc),
      where("status", "==", "APARTADO")
    );
    const querySnapshot = await getDocs(q);
    const promises = [];
    querySnapshot.forEach((doc) => {
      const boletoId = doc.id;
      const data = { status: "COMPRADO" };
      promises.push(addRegistroEspecifico("BOLETOS", boletoId, data));
    });

    await Promise.all(promises);
    response.statusresponse = true;
  } catch (error) {
    response.error = error;
    mostrarMensajeError(
      "Error al validar todos los boletos",
      "Hubo un problema al intentar validar todos los boletos. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
};

export async function getCategorias() {
  const categoriasResponse = { statusresponse: false, data: [] };
  try {
    const q = query(collection(db, "CATEGORIAS"), where("activo", "==", true));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const categoria = doc.data();
      categoria.id = doc.id;
      categoriasResponse.data.push(categoria);
    });

    categoriasResponse.statusresponse = size(categoriasResponse.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener categorías",
      "Hubo un problema al obtener las categorías. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return categoriasResponse;
}

export async function getSorteosCategoria(
  id,
  startDate,
  endDate,
  filterState,
  ubicacion
) {
  const sorteosResponse = { statusresponse: false, data: [] };

  const categoriaDoc = doc(db, "CATEGORIAS", id);
  const categoriaQuery = where("categoria", "==", categoriaDoc);
  const statusQuery = where("status", "==", "ACTIVO");
  const prioridadQuery = orderBy("prioridad", "asc");
  const prioridadFecha = orderBy("fechaInicio", "desc");

  let sorteosQuery = query(
    collection(db, "SORTEOS"),
    categoriaQuery,
    statusQuery,
    prioridadQuery,
    prioridadFecha
  );

  if (startDate && endDate) {
    const adjustedStartDate = startOfDay(startDate);
    const adjustedEndDate = endOfDay(endDate);
    sorteosQuery = query(
      sorteosQuery,
      where("fechaInicio", ">=", adjustedStartDate),
      where("fechaInicio", "<=", adjustedEndDate)
    );
  }

  if (!isEmpty(ubicacion)) {
    sorteosQuery = query(
      sorteosQuery,
      where("ubicacion.estado", "==", ubicacion)
    );
  }

  if (filterState && !isEmpty(filterState.value)) {
    switch (filterState.filter) {
      case "nombre_sorteo":
        const nombreSorteoNormalizado = normalizarTexto(filterState.value);

        // sorteosQuery = query(
        //   sorteosQuery,
        //   where("nombre", ">=", filterState.value),
        //   where("nombre", "<=", filterState.value + "\uf8ff")
        // );
        sorteosQuery = query(
          sorteosQuery,
          where(
            "nombre_palabras_clave",
            "array-contains",
            nombreSorteoNormalizado
          )
        );
        break;
      case "metodo_seleccion_ganadores":
        const metodoDoc = doc(
          db,
          "METODOS_SELECCION_GANADORES",
          filterState.value
        );
        sorteosQuery = query(
          sorteosQuery,
          where("metodoSeleccionGanadores", "==", metodoDoc)
        );
        break;
      case "costo_participacion":
        sorteosQuery = query(
          sorteosQuery,
          where("costoParticipacion", ">=", filterState.value.min || 0),
          where("costoParticipacion", "<=", filterState.value.max || 0)
        );
        break;
      default:
        break;
    }
  }

  try {
    const querySnapshot = await getDocs(sorteosQuery);

    querySnapshot.forEach((doc) => {
      const categoria = doc.data();
      categoria.id = doc.id;
      sorteosResponse.data.push(categoria);
    });

    sorteosResponse.statusresponse = sorteosResponse.data.length > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener sorteos por categoría",
      "Hubo un problema al obtener los sorteos por categoría. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return sorteosResponse;
}

export async function getPreguntasFrecuentes() {
  const preguntasResponse = { statusresponse: false, data: [] };
  try {
    const q = query(
      collection(db, "PREGUNTAS_FRECUENTES"),
      where("activo", "==", true)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const pregunta = doc.data();
      pregunta.id = doc.id;
      preguntasResponse.data.push(pregunta);
    });

    preguntasResponse.statusresponse = size(preguntasResponse.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener preguntas frecuentes",
      "Hubo un problema al obtener las preguntas frecuentes. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return preguntasResponse;
}

export async function getContacto() {
  const contactoResponse = { statusresponse: false, data: [] };
  try {
    const q = query(
      collection(db, "SOPORTE_CONTACTO"),
      where("activo", "==", true)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const contacto = doc.data();
      contacto.id = doc.id;
      contactoResponse.data.push(contacto);
    });

    contactoResponse.statusresponse = size(contactoResponse.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener información de contacto",
      "Hubo un problema al obtener la información de contacto. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return contactoResponse;
}

export async function getMembresias() {
  const membresiaResponse = { statusresponse: false, data: [] };
  try {
    const q = query(
      collection(db, "MEMBRESIAS"),
      where("visibilidad", "==", true)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const membresia = doc.data();
      membresia.id = doc.id;
      membresiaResponse.data.push(membresia);
    });

    membresiaResponse.statusresponse = size(membresiaResponse.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener membresías",
      "Hubo un problema al obtener las membresías. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return membresiaResponse;
}

export async function getMetodosSeleccionGanadores() {
  const metodosResponse = { statusresponse: false, data: [] };
  try {
    const q = query(
      collection(db, "METODOS_SELECCION_GANADORES"),
      where("activo", "==", true)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const metodo = doc.data();
      metodo.id = doc.id;
      metodosResponse.data.push(metodo);
    });

    metodosResponse.statusresponse = size(metodosResponse.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener métodos de selección de ganadores",
      "Hubo un problema al obtener los métodos de selección de ganadores. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return metodosResponse;
}

export async function getSorteosStatus(
  status,
  startDate,
  endDate,
  filterState,
  ubicacion
) {
  const sorteosResponse = { statusresponse: false, data: [] };

  let q = query(
    collection(db, "SORTEOS"),
    where("status", "==", status),
    orderBy("prioridad", "asc"),
    orderBy("fechaInicio", "desc")
  );

  if (process.env.REACT_APP_THEME !== "SORTEOS_HERRADURA_ROJA") {
    const proveedorRef = doc(db, "USUARIOS", PERFIL.ID);
    q = query(q, where("proveedor", "==", proveedorRef));
  }

  if (startDate && endDate) {
    const adjustedStartDate = startOfDay(startDate);
    const adjustedEndDate = endOfDay(endDate);
    q = query(
      q,
      where("fechaInicio", ">=", adjustedStartDate),
      where("fechaInicio", "<=", adjustedEndDate)
    );
  }

  if (!isEmpty(ubicacion)) {
    q = query(q, where("ubicacion.estado", "==", ubicacion));
  }

  if (filterState && !isEmpty(filterState.value)) {
    switch (filterState.filter) {
      case "nombre_sorteo":
        const nombreSorteoNormalizado = normalizarTexto(filterState.value);
        // q = query(
        //   q,
        //   where("nombre", ">=", filterState.value),
        //   where("nombre", "<=", filterState.value + "\uf8ff")
        // );
        q = query(
          q,
          where(
            "nombre_palabras_clave",
            "array-contains",
            nombreSorteoNormalizado
          )
        );
        break;
      case "metodo_seleccion_ganadores":
        const metodoDoc = doc(
          db,
          "METODOS_SELECCION_GANADORES",
          filterState.value
        );
        q = query(q, where("metodoSeleccionGanadores", "==", metodoDoc));
        break;
      case "costo_participacion":
        q = query(
          q,
          where("costoParticipacion", ">=", filterState.value.min || 0),
          where("costoParticipacion", "<=", filterState.value.max || 0)
        );
        break;
      default:
        break;
    }
  }

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const sorteo = doc.data();
      sorteo.id = doc.id;
      sorteosResponse.data.push(sorteo);
    });

    sorteosResponse.statusresponse = size(sorteosResponse.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener sorteos",
      "Hubo un problema al obtener los sorteos. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return sorteosResponse;
}

export async function getPublicidad() {
  const publicidadResponse = { statusresponse: false, data: [] };
  try {
    const q = query(
      collection(db, "ANUNCIOS_PUBLICIDAD"),
      orderBy("prioridad", "asc")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const publicidad = doc.data();
      publicidad.id = doc.id;
      publicidadResponse.data.push(publicidad);
    });

    publicidadResponse.statusresponse = size(publicidadResponse.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener publicidad",
      "Hubo un problema al obtener los datos de publicidad. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return publicidadResponse;
}

export const sendEmailResetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return { statusresponse: true, error: null };
  } catch (error) {
    mostrarMensajeError(
      "Error al enviar correo electrónico de restablecimiento de contraseña",
      "Hubo un problema al enviar el correo electrónico de restablecimiento de contraseña. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
    return { statusresponse: false, error };
  }
};

export async function getRolesUsuarios() {
  const rolesResponse = { statusresponse: false, data: [] };
  try {
    const q = query(collection(db, "ROLES"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const rol = doc.data();
      rol.id = doc.id;
      rolesResponse.data.push(rol);
    });

    rolesResponse.statusresponse = size(rolesResponse.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener la información del usuario",
      "Hubo un problema al obtener la información. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return rolesResponse;
}

export async function getNotificaciones(usuario) {
  const notificacionesResponse = { statusresponse: false, data: [] };
  try {
    const usuarioDoc = doc(db, "USUARIOS", usuario);

    const q = query(
      collection(db, "NOTIFICACIONES"),
      where("usuario", "==", usuarioDoc),
      orderBy("fecha", "desc")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const notificacion = doc.data();
      notificacion.id = doc.id;
      notificacionesResponse.data.push(notificacion);
    });

    notificacionesResponse.statusresponse =
      size(notificacionesResponse.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener notificaciones",
      "Hubo un problema al obtener tus notificaciones. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return notificacionesResponse;
}

export async function getNotificacionesStatus(usuario, status) {
  const notificacionesResponse = { statusresponse: false, data: [] };
  try {
    const usuarioDoc = doc(db, "USUARIOS", usuario);

    const q = query(
      collection(db, "NOTIFICACIONES"),
      where("usuario", "==", usuarioDoc),
      where("estado", "==", status),
      orderBy("fecha", "desc")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const notificacion = doc.data();
      notificacion.id = doc.id;
      notificacionesResponse.data.push(notificacion);
    });

    notificacionesResponse.statusresponse =
      size(notificacionesResponse.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener notificaciones",
      "Hubo un problema al obtener tus notificaciones. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return notificacionesResponse;
}

export async function eliminarCampo(coleccion, documentoId, campo) {
  try {
    const docRef = doc(db, coleccion, documentoId);
    await updateDoc(docRef, {
      [campo]: deleteField(),
    });
    return true;
  } catch (error) {
    mostrarMensajeError(
      "Error al procesar la solicitud",
      "Hubo un problema al procesar tu solicitud. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
    return false;
  }
}

export const followUser = async (userId, userIdToFollow) => {
  try {
    const userData = await obternerRegistroxID("USUARIOS", userId);
    const userToFollowData = await obternerRegistroxID(
      "USUARIOS",
      userIdToFollow
    );

    await addRegistroEspecifico("USUARIOS", userIdToFollow, {
      seguidores: [...userToFollowData.data.seguidores, userId],
    });

    await addRegistroEspecifico("USUARIOS", userId, {
      siguiendo: [...userData.data.siguiendo, userIdToFollow],
    });
  } catch (error) {
    mostrarMensajeError(
      "Error al seguir al usuario",
      "Hubo un problema al intentar seguir al usuario. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
};

export const unfollowUser = async (userId, userIdToUnfollow) => {
  try {
    const userData = await obternerRegistroxID("USUARIOS", userId);
    const userToUnfollowData = await obternerRegistroxID(
      "USUARIOS",
      userIdToUnfollow
    );

    const updatedFollowers = userToUnfollowData.data.seguidores.filter(
      (id) => id !== userId
    );
    await addRegistroEspecifico("USUARIOS", userIdToUnfollow, {
      seguidores: updatedFollowers,
    });

    const updatedFollowing = userData.data.siguiendo.filter(
      (id) => id !== userIdToUnfollow
    );
    await addRegistroEspecifico("USUARIOS", userId, {
      siguiendo: updatedFollowing,
    });
  } catch (error) {
    mostrarMensajeError(
      "Error al dejar de seguir al usuario",
      "Hubo un problema al intentar dejar de seguir al usuario. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
};

export async function getSolicitudesMembresias() {
  const solicitudesMembresiaResponse = { statusresponse: false, data: [] };
  try {
    const q = query(
      collection(db, "SOLICITUDES_MEMBRESIA"),
      where("status", "==", "PENDIENTE"),
      orderBy("fechaSolicitud", "desc")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const solicitud = doc.data();
      solicitud.id = doc.id;
      solicitudesMembresiaResponse.data.push(solicitud);
    });

    solicitudesMembresiaResponse.statusresponse =
      size(solicitudesMembresiaResponse.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener solicitudes de membresías",
      "Hubo un problema al obtener las solicitudes de membresías. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return solicitudesMembresiaResponse;
}

export async function getConsumidores(rol) {
  const consumidoresResponse = { statusresponse: false, data: [] };
  try {
    const typeDoc = doc(db, "ROLES", rol);
    const q = query(
      collection(db, "USUARIOS"),
      where("type", "==", typeDoc),
      orderBy("fechaCreacion", "desc")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const consumidor = doc.data();
      consumidor.id = doc.id;
      consumidoresResponse.data.push(consumidor);
    });

    consumidoresResponse.statusresponse = size(consumidoresResponse.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener usuarios consumidores",
      "Hubo un problema al obtener usuarios consumidores. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return consumidoresResponse;
}

export async function getMiSolicitudMembresia(usuario) {
  const membresiaResponse = { statusresponse: false, data: [] };
  try {
    const userDoc = doc(db, "USUARIOS", usuario);

    const q = query(
      collection(db, "SOLICITUDES_MEMBRESIA"),
      where("status", "==", "PENDIENTE"),
      where("usuario", "==", userDoc)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const membresia = doc.data();
      membresia.id = doc.id;
      membresiaResponse.data.push(membresia);
    });

    membresiaResponse.statusresponse = size(membresiaResponse.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener solicitud de membresía",
      "Hubo un problema al obtener la solicitud de membresía. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return membresiaResponse;
}

export async function getBoletosAdmin() {
  const boletosResponse = { statusresponse: false, data: [] };
  try {
    const q = query(collection(db, "BOLETOS"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const boleto = doc.data();
      boleto.id = doc.id;
      boletosResponse.data.push(boleto);
    });

    boletosResponse.statusresponse = size(boletosResponse.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener boletos",
      "Hubo un problema al obtener boletos. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return boletosResponse;
}

export async function getBoletosApartadosAdmin() {
  const boletosResponse = { statusresponse: false, data: [] };
  try {
    const fechaLimite = new Date();
    fechaLimite.setHours(fechaLimite.getHours() - 12);

    const q = query(
      collection(db, "BOLETOS"),
      where("status", "==", "APARTADO"),
      where("fechaApartado", "<=", fechaLimite)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const boleto = doc.data();
      boleto.id = doc.id;
      boletosResponse.data.push(boleto);
    });

    boletosResponse.statusresponse = size(boletosResponse.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener boletos",
      "Hubo un problema al obtener boletos. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return boletosResponse;
}

export async function getBoletosProveedor(proveedor, startDate, endDate) {
  const response = { statusresponse: false, data: [] };
  try {
    const proveedorDoc = doc(db, "USUARIOS", proveedor);

    let q = query(
      collection(db, "BOLETOS"),
      where("proveedor", "==", proveedorDoc)
    );

    if (startDate && endDate) {
      q = query(
        q,
        where("fechaApartado", ">=", startDate),
        where("fechaApartado", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const boleto = doc.data();
      boleto.id = doc.id;
      response.data.push(boleto);
    });

    response.statusresponse = size(response.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener boletos",
      "Hubo un problema al obtener los boletos. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
}

export async function getMisComisiones(proveedor, startDate, endDate) {
  const response = { statusresponse: false, data: [] };

  try {
    const proveedorDoc = doc(db, "USUARIOS", proveedor);

    let q = query(
      collection(db, "COMISIONES"),
      where("proveedor", "==", proveedorDoc),
      where("status", "==", "PENDIENTE")
    );

    if (startDate && endDate) {
      q = query(
        q,
        where("fecha", ">=", startDate),
        where("fecha", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const boleto = doc.data();
      boleto.id = doc.id;
      response.data.push(boleto);
    });

    response.statusresponse = size(response.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener comisiones",
      "Hubo un problema al obtener las comisiones. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
}

export const reauthenticate = async (password) => {
  try {
    const user = ObtenerUsuario();
    const credential = EmailAuthProvider.credential(user.email, password);
    return await reauthenticateWithCredential(user, credential);
  } catch (error) {
    mostrarMensajeError(
      "Error al volver a autenticar",
      "Hubo un problema al volver a autenticar. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }

  return user;
};

export const changePassword = async (password) => {
  try {
    const user = ObtenerUsuario();
    await updatePassword(user, password);
    return true;
  } catch (error) {
    mostrarMensajeError(
      "Error al cambiar la contraseña",
      "Hubo un problema al cambiar la contraseña. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
    return false;
  }
};

export const verificarExistenciaUsuario = async (email) => {
  try {
    const usersRef = collection(db, "USUARIOS");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].data();
    } else {
      return null;
    }
  } catch (error) {
    mostrarMensajeError(
      "Error al verificar la existencia del usuario",
      "Hubo un problema al verificar si el usuario existe. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
    throw error;
  }
};

export function getMantenimientoStatus(callback) {
  try {
    const q = query(collection(db, "MANTENIMIENTO"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const mantenimientos = [];
      querySnapshot.forEach((doc) => {
        const mantenimiento = doc.data();
        mantenimiento.id = doc.id;
        mantenimientos.push(mantenimiento);
      });

      callback({
        statusresponse: size(mantenimientos) > 0,
        data: mantenimientos,
      });
    });

    return unsubscribe;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener el estado de mantenimiento",
      "Hubo un problema al obtener el estado de mantenimiento. Por favor, inténtalo de nuevo más tarde."
    );
    callback({ statusresponse: false, error });
  }
}

export async function getDocumentosLegales(archivo) {
  const response = { statusresponse: false, data: [] };
  try {
    const q = query(collection(db, "LEGAL"), where("archivo", "==", archivo));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const archivo = doc.data();
      archivo.id = doc.id;
      response.data.push(archivo);
    });

    response.statusresponse = size(response.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener documentos",
      "Hubo un problema al obtener los documentos. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
}

export const sendVerificationEmail = async () => {
  const user = ObtenerUsuario();
  if (!user) {
    mostrarMensajeError("Error", "No hay un usuario autenticado.");
    return Promise.reject(new Error("No hay un usuario autenticado."));
  }

  return sendEmailVerification(user)
    .then(() => {
      mostrarMensajeExito(
        "Correo enviado",
        "Se ha enviado un correo de verificación a tu email."
      );
    })
    .catch((error) => {
      mostrarMensajeError(
        "Error",
        `Error enviando el correo de verificación: ${error.message}`
      );
      throw error;
    });
};

export const actualizarCorreoElectronico = async (nuevoCorreo) => {
  const usuarioActual = ObtenerUsuario();

  if (!usuarioActual) {
    return Promise.reject(new Error("No hay un usuario autenticado."));
  }

  return verifyBeforeUpdateEmail(usuarioActual, nuevoCorreo)
    .then(() => {
      return Promise.resolve("¡Correo electrónico actualizado!");
    })
    .catch((error) => {
      return Promise.reject(
        new Error(`Error al actualizar el correo electrónico: ${error.message}`)
      );
    });
};

export function getNotificacionesStatusRealTime(usuario, status, callback) {
  try {
    const usuarioDoc = doc(db, "USUARIOS", usuario);

    const q = query(
      collection(db, "NOTIFICACIONES"),
      where("usuario", "==", usuarioDoc),
      where("estado", "==", status),
      orderBy("fecha", "desc")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const notificaciones = [];
      querySnapshot.forEach((doc) => {
        const notificacion = doc.data();
        notificacion.id = doc.id;
        notificaciones.push(notificacion);
      });

      callback({
        statusresponse: size(notificaciones) > 0,
        data: notificaciones,
      });
    });

    return unsubscribe;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener notificaciones",
      "Hubo un problema al obtener tus notificaciones. Por favor, inténtalo de nuevo más tarde."
    );
    callback({ statusresponse: false, data: [] });
  }
}

export async function getMisBoletosBySorteo(usuario, sorteo) {
  const response = { statusresponse: false, data: [] };
  try {
    const usuarioDoc = doc(db, "USUARIOS", usuario);
    const sorteoDoc = doc(db, "SORTEOS", sorteo);

    const q = query(
      collection(db, "BOLETOS"),
      where("usuario", "==", usuarioDoc),
      where("sorteo", "==", sorteoDoc),
      where("status", "in", ["APARTADO", "COMPRADO"])
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const boleto = doc.data();
      boleto.id = doc.id;
      response.data.push(boleto);
    });

    response.statusresponse = size(response.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener boletos",
      "Hubo un problema al obtener los boletos. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
}

export async function getUsuariosByRol(rol) {
  const response = { statusresponse: false, data: [] };
  try {
    const rolDoc = doc(db, "ROLES", rol);

    const q = query(collection(db, "USUARIOS"), where("type", "==", rolDoc));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const usuario = doc.data();
      usuario.id = doc.id;
      response.data.push(usuario);
    });

    response.statusresponse = size(response.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener usuarios",
      "Hubo un problema al obtener los usuarios. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
}

export async function getUsuariosByMembresia(membresia) {
  const response = { statusresponse: false, data: [] };
  try {
    const membresiaDoc = doc(db, "MEMBRESIAS", membresia);

    const q = query(
      collection(db, "USUARIOS"),
      where("membresia", "==", membresiaDoc)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const usuario = doc.data();
      usuario.id = doc.id;
      response.data.push(usuario);
    });

    response.statusresponse = size(response.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener usuarios",
      "Hubo un problema al obtener los usuarios. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
}

export async function getComisiones(status) {
  const response = { statusresponse: false, data: [] };
  try {
    const q = query(
      collection(db, "COMISIONES"),
      where("status", "==", status)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const comision = doc.data();
      comision.id = doc.id;
      response.data.push(comision);
    });

    response.statusresponse = size(response.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener comisiones",
      "Hubo un problema al obtener las comisiones. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
}

export async function getDivisas() {
  const response = { statusresponse: false, data: [] };
  try {
    const q = query(collection(db, "DIVISAS"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const divisa = doc.data();
      divisa.id = doc.id;
      response.data.push(divisa);
    });

    response.statusresponse = size(response.data) > 0;
  } catch (error) {
    mostrarMensajeError(
      "Error al obtener divisas",
      "Hubo un problema al obtener las divisas. Por favor, inténtalo de nuevo más tarde."
    );
    await registrarError(error);
  }
  return response;
}

export const registrarAccion = async (
  accion,
  detalles,
  userID,
  tipoCRUD,
  coleccionModificar
) => {
  const usuarioRef = doc(db, "USUARIOS", userID);
  const coleccion = "LOGS";
  const data = {
    accion,
    detalles,
    usuario: usuarioRef,
    fecha: new Date(),
    tipoCRUD,
    coleccionModificar,
  };

  const resultado = await addRegistro(coleccion, data);

  if (!resultado.statusresponse) {
    console.error("Error al registrar la acción:", resultado.error);
  }

  return resultado;
};

export const registrarError = async (error) => {
  const usuarioActual = ObtenerUsuario();
  const uid = usuarioActual?.uid;

  const coleccion = "ERRORES";
  const data = {
    mensajeError: error?.message,
    stack: error?.stack,
    nombre: error?.name || "Error desconocido",
    fecha: new Date(),
    usuario: uid ? doc(db, "USUARIOS", uid) : "ANONIMO",
  };

  const resultado = await addRegistro(coleccion, data);

  if (!resultado.statusresponse) {
    console.error("Error al registrar el error:", resultado.error);
  }

  return resultado;
};
